import * as React from "react"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { CircularProgress, Paper, TextField } from "@mui/material"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { Button, Grid, AppBar, Toolbar } from "@mui/material"
import { Link } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import HelpIcon from "@mui/icons-material/Help"
import { useOutletContext } from "react-router-dom/dist"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import useApi from "../hooks/useApi"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { Helmet } from "react-helmet"
import apiClient from "../api/apiClient"
import Paginate from "../components/mui/Paginate"
import ShopFilterSidebar from "../components/sidebar-filter/ProductFilterSidebar"
import Calender from "../components/mui/Calendar"
import { formatDate, objectToQueryString } from "../modules/helpers"
import LoadingOverlay from "../components/mui/LoadingOverlay"
import downloadFile from "../modules/downloadFile"
import OptionsMenus from "../components/core/OptionsMenu"
import { showModal } from "../redux/modalSlice"
import SelectOption from "../components/mui/SelectOption"
import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL
const SubmittedReport = () => {
  const [count, setCount] = useState(0)
  const [showFilter, setShowFilter] = useState(false)
  const [date, setDate] = useState([])
  const [reset, setReset] = useState(false)
  const intialFilters = {
    start_date: "",
    end_date: "",
    date_search_type: "client",
    keyword: "barcode",
    search: "",
    archive: false
  }
  const [filters, setFilters] = useState(intialFilters)
  const onReset = () => {
    setFilters(intialFilters)
    setDate([])
    setReset(true)
  }
  const onFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value })
  }
  const navigate = useNavigate()

  const { request, error, data, loading } = useApi(route => apiClient.get(route))
  console.log(data, "fghu data")

  useEffect(() => {
    if (reset) fetchData()
  }, [reset])
  const fetchData = async (page = 1) => {
    const filterObject = {
      start_date: date?.[0] || "",
      end_date: date?.[1] || "",
      date_search_type: filters.date_search_type,
      archive: filters.archive,
      [filters.keyword]: filters.search
    }
    if (page == 1 && count) setCount(0)
    //   const result = await request(`/sample/?sampleStatus=${status}`);
    const result = await request(
      `/sample/?limit=10&page=${page}&${objectToQueryString(filterObject)}`
    )
    setReset(false)
    if (!count || page == 1 || count !== result.data.count) setCount(result.data.count)
  }

  const handlePageChange = (event, value) => {
    fetchData(value)
  }

  const handleViewReport = barcode => {
    navigate("/get-report/" + barcode)
  }

  useEffect(() => {
    fetchData()
  }, [])
  const [isDownloading, setIsDownloading] = useState(false)
  const handleDownload = async barcode => {
    setIsDownloading(true)

    try {
      const response = await axios.get(`${baseURL}report/download/${barcode}`, {
        responseType: "blob", // Important for handling binary data (PDF)
        headers: { Accept: "application/pdf" },
        timeout: 60000 // Optional: Set timeout for large files
      })

      if (response.status !== 200) {
        throw new Error("Failed to download")
      }

      const blob = new Blob([response.data], { type: "application/pdf" })
      console.log("Blob size:", blob.size)

      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.style.display = "none"
      a.href = url
      a.download = `report_${barcode}.pdf`
      document.body.appendChild(a)
      a.click()

      // Cleanup
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 60000)
    } catch (error) {
      console.error("Download error:", error)
      toast.error("Failed to download the report")
    } finally {
      setIsDownloading(false)
    }
  }

  const [showModalReAssign, setShowModalReAssign] = useState(false)
  const [showRecSampleMDL, setShowRecSampleMDL] = useState(false)
  const [selectedSample, setSelectedSample] = useState("")
  const handleReAssignSample = data => {
    setShowModalReAssign(true)
    setSelectedSample(data)
  }

  return (
    <div>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <LoadingOverlay open={isDownloading} />
      <div className="container">
        <div className="d-flex justify-content-between">
          <div>
            <ShopFilterSidebar
              openFilter={showFilter}
              onOpenFilter={() => setShowFilter(true)}
              onCloseFilter={() => setShowFilter(false)}
              handleChange={onFilterChange}
              values={filters}
              onReset={onReset}
              fetchData={fetchData}
              date={date}
              setDate={setDate}
            />
          </div>
          <div>
            <Button
              sx={{ borderRadius: 20, whiteSpace: "nowrap" }}
              className="btn_bg_secondery"
              variant="contained"
              onClick={() => {
                setShowRecSampleMDL(true)
              }}>
              Receive New Sample
            </Button>
          </div>
        </div>
      </div>
      <Grid container>
        <Grid className=" d-flex justify-content-center" container item md={12} xs={12}>
          <Grid item xs={12} md={8}>
            <h5 className="my-3">Reports data:</h5>
            {!loading ? (
              <>
                {data.map((item, index) => {
                  const { client, technician, sampleStatus, ...otherSampleItem } = item

                  return (
                    <div
                      className=" row justify-content-center d-flex gy-2 gx-2 flex-wrap px-4 mb-5"
                      style={{
                        backgroundColor: "#F1F1F5",
                        // color:"white",
                        borderRadius: 15,
                        alignItems: "center",
                        paddingTop: 40,
                        paddingBottom: 40
                      }}>
                      <div className="col-1 d-flex align-items-start h-100">
                        <AccountCircleIcon fontSize="large" />
                      </div>
                      <div className="col-md-8">
                        <div className="row flex-wrap">
                          {/* First set of information */}

                          <div className="col-6">
                            <Typography
                              className="fs_14"
                              variant="body2"
                              sx={{ fontWeight: "bold" }}>
                              Name:
                            </Typography>
                            <Typography className="fs_10px" sx={{ fontWeight: "bold" }}>
                              {client.firstname + " " + client.lastname}
                            </Typography>
                          </div>

                          <div className="col-6">
                            <Typography
                              className="fs_14"
                              variant="body2"
                              sx={{ fontWeight: "bold" }}>
                              Address:
                            </Typography>
                            <Typography className="fs_10px">{client.address}</Typography>
                          </div>

                          {/* Second set of information */}
                          <div className="col-6">
                            <Typography variant="body2" component="p" sx={{ fontWeight: "bold" }}>
                              Email
                            </Typography>
                            <Typography variant="body2" component="p" sx={{ fontSize: "0.7rem" }}>
                              {client.email}
                            </Typography>
                          </div>

                          <div className="col-6">
                            <Typography variant="body2" component="p" sx={{ fontWeight: "bold" }}>
                              Contact
                            </Typography>
                            <Typography variant="body2" component="p" sx={{ fontSize: "0.7rem" }}>
                              {client.phone_no}
                            </Typography>
                          </div>

                          {/* Third set of information */}
                          <div className="col-6">
                            <Typography variant="body2" component="p" sx={{ fontWeight: "bold" }}>
                              Pet Name
                            </Typography>
                            <Typography variant="body2" component="p" sx={{ fontSize: "0.7rem" }}>
                              {client.pet.pet_name}
                            </Typography>
                          </div>

                          <div className="col-6">
                            <Typography variant="body2" component="p" sx={{ fontWeight: "bold" }}>
                              Barcode
                            </Typography>
                            <Typography variant="body2" component="p" sx={{ fontSize: "0.7rem" }}>
                              {client.barcode}
                            </Typography>
                          </div>
                          <div className="col-6">
                            <Typography variant="body2" component="p" sx={{ fontWeight: "bold" }}>
                              Client Register Date
                            </Typography>
                            <Typography variant="body2" component="p" sx={{ fontSize: "0.7rem" }}>
                              {formatDate(client?.createdAt, "MMMM D, YYYY [at] h:mma")}
                            </Typography>
                          </div>
                          <div className="col-6">
                            <Typography variant="body2" component="p" sx={{ fontWeight: "bold" }}>
                              Sample Receive Date
                            </Typography>
                            <Typography variant="body2" component="p" sx={{ fontSize: "0.7rem" }}>
                              {formatDate(otherSampleItem?.createdAt, "MMMM D, YYYY [at] h:mma")}
                            </Typography>
                          </div>
                          {otherSampleItem?.submitted_at && (
                            <div className="col-6">
                              <Typography variant="body2" component="p" sx={{ fontWeight: "bold" }}>
                                Report Create Date
                              </Typography>
                              <Typography variant="body2" component="p" sx={{ fontSize: "0.7rem" }}>
                                {formatDate(
                                  otherSampleItem?.submitted_at,
                                  "MMMM D, YYYY [at] h:mma"
                                )}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 d-flex flex-column flex-sm-row">
                        <Box className="">
                          <Typography
                            className="fs_14px"
                            variant="span"
                            sx={{ display: "block", ml: 1, fontWeight: "bold" }}>
                            Technician name
                          </Typography>
                          <Typography
                            className="fs_14px"
                            variant="span"
                            sx={{ display: "block", ml: 1 }}>
                            {technician?.name}
                          </Typography>
                          {/* <Button
                          sx={{}}
                          onClick={() => handleDownload(client.barcode)}
                          disabled={sampleStatus !== "SUB"}
                          className=" m-2 btn btn_bg_secondery"
                          variant="contained"
                        >
                          Generate Report
                        </Button>
                        <Button
                          sx={{}}
                          onClick={() => handleViewReport(client.barcode)}
                          disabled={sampleStatus !== "SUB"}
                          className=" m-2 btn btn_bg_secondery"
                          variant="contained"
                        >
                          View Report
                        </Button> */}
                          <OptionsMenus
                            handleReportGenerate={() => handleDownload(client.barcode)}
                            handleReportView={() => handleViewReport(client.barcode)}
                            handleReAssignTechnician={() => handleReAssignSample(item)}
                            reportButtonsDisable={sampleStatus !== "SUB"}
                          />
                        </Box>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            <div className={`${loading && "d-none"}`}>
              <Paginate style={{ color: "white" }} count={count} onChange={handlePageChange} />
            </div>
          </Grid>
        </Grid>
      </Grid>

      {showModalReAssign && (
        <ReAssignModal
          show={showModalReAssign}
          onHide={() => setShowModalReAssign(false)}
          data={selectedSample}
          fetchData={fetchData}
        />
      )}
      {/* {showModalReAssign && ( */}
      <AssignModal
        show={showRecSampleMDL}
        onHide={() => setShowRecSampleMDL(false)}
        // data={selectedSample}
        fetchData={fetchData}
      />
      {/* )} */}
    </div>
  )
}

function AssignModal({ fetchData, onHide, show }) {
  let initialstate = {
    technician: "",
    barcode: ""
  }
  const dispatch = useDispatch()
  const [sample, setSample] = useState(initialstate)
  const [techtype, setTechType] = useState("")
  const [load, setLoad] = useState(false)
  const handleChange = (key, value) => {
    setSample({ ...sample, [key]: value })
  }
  const handleSubmit = async e => {
    e.preventDefault()
    setLoad(true)
    const result = await apiClient.post(`/sample/receive-sample/`, sample)
    setLoad(false)
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Sample",
          message: result.data.message
        })
      )
    fetchData()
    onHide()
    return dispatch(
      showModal({
        error: false,
        title: "Sample Received",
        message: result.data.message
      })
    )
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header className=" " closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="text-center w-100">
          <HelpIcon style={{ fontSize: "55px", color: "#7C828C" }} />
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <h4 className="text-center">Assign this Sample</h4>
          <p className="text-center">
            Assign this sample to a specific <br />
            technician.
          </p>

          <div className="row justify-content-center gap-2 my-2">
            <div className="col-12">
              <TextField
                required
                label="Barcode"
                // value={client.veterinarian}
                name="barcode"
                value={sample.barcode}
                onChange={e => handleChange("barcode", e.target.value)}
                variant="outlined"
                fullWidth
              />
            </div>
            <div className="col-12">
              <SelectOption
                style={{ width: "100%" }}
                data={[
                  { value: "TN", label: "Normal" },
                  { value: "PPTN", label: "PP Technician" },
                  { value: "EXP", label: "Exp Technician" }
                ]}
                id="outlined-basic"
                value={techtype}
                onChange={e => setTechType(e.target.value)}
                label="Technician Type"
                variant="outlined"
              />
            </div>
            <div className="col-12">
              <SelectDropdown
                label="Technician"
                sx={{ backgroundColor: "white" }}
                techType={techtype}
                value={sample.technician}
                name={"technician"}
                handleChange={handleChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            type="submit"
            size="large"
            // onClick={()=>handleSubmit()}
            variant="contained"
            className=" btn_bg_secondery px-5">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
function ReAssignModal({ fetchData, onHide, show, data }) {
  let initialstate = {
    technician: data.technician._id
  }
  const dispatch = useDispatch()
  const [sample, setSample] = useState(initialstate)
  const [techtype, setTechType] = useState(data.technician?.role || "")
  const [load, setLoad] = useState(false)
  const handleChange = (key, value) => {
    setSample({ ...sample, [key]: value })
  }
  const handleSubmit = async e => {
    e.preventDefault()
    setLoad(true)
    const result = await apiClient.patch(`/sample/${data._id}/res-assign-sample/`, sample)
    setLoad(false)
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Sample",
          message: result.data.message
        })
      )
    fetchData()
    onHide()
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header className=" " closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="text-center w-100">
          <HelpIcon style={{ fontSize: "55px", color: "#7C828C" }} />
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <h4 className="text-center">Re-Assign this Sample</h4>
          <p className="text-center">
            Re-Assign this sample to a specific <br />
            technician.
          </p>

          <div className="row justify-content-center gap-2 my-2">
            <div className="col-12">
              <SelectOption
                style={{ width: "100%" }}
                data={[
                  { value: "TN", label: "Normal" },
                  { value: "PPTN", label: "PP Technician" },
                  { value: "EXP", label: "Exp Technician" }
                ]}
                id="outlined-basic"
                value={techtype}
                onChange={e => setTechType(e.target.value)}
                label="Technician Type"
                variant="outlined"
              />
            </div>
            <div className="col-12">
              <SelectDropdown
                label="Technician"
                sx={{ backgroundColor: "white" }}
                techType={techtype}
                value={sample.technician}
                name={"technician"}
                handleChange={handleChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            type="submit"
            size="large"
            // onClick={()=>handleSubmit()}
            variant="contained"
            className=" btn_bg_secondery px-5">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const SelectDropdown = ({ name, value, handleChange, techType, ...otherProps }) => {
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState()
  const { request } = useApi(route => apiClient.get(route))
  useEffect(() => {
    fetchData(1)
  }, [techType])

  async function fetchData(pageNo = page) {
    const result = await request(`/auth/all-users-t/?page=${pageNo}&role=${techType}`)
    if (result.data.results)
      if (pageNo === 1) setData(result.data?.results)
      else setData([...data, ...result.data?.results])
    setPage(pageNo + 1)
    if (!count) setCount(result.data.count)
  }

  const handleScroll = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      if (count > data.length) fetchData()
    }
  }

  return (
    <SelectOption
      fieldNames={{ value: "_id", name: "name" }}
      required
      inputProps={{ maxLength: 150 }}
      id="outlined-name"
      value={value}
      onChange={e => handleChange(name, e.target.value)}
      size={"large"}
      data={data}
      fullWidth
      style={{ width: "100%" }}
      MenuProps={{
        PaperProps: {
          onScroll: handleScroll,
          style: {
            maxHeight: "300px",
            overflowY: "auto"
          }
        }
      }}
      {...otherProps}
    />
  )
}

export default SubmittedReport
