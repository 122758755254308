import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Typography
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Api } from "@mui/icons-material"
import apiClient from "../../api/apiClient"
import BackButton from "../../components/mui/BackButton"
import downloadFile from "../../modules/downloadFile"
import LoadingOverlay from "../../components/mui/LoadingOverlay"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { showModal } from "../../redux/modalSlice"

function ClientPetBio() {
  const [data, setData] = useState()
  const { id } = useParams()
  //console.log(id,"asasasasasasasasa")
  const [sampleStatus, setSampleStatus] = useState()
  const fetchData = async () => {
    const result = await apiClient.get(`/sample/${id}`)
    // const result = await apiClient.get(`/client/${'64a9092d260c220ce1352a64'}`);
    //console.log(result, 'client pet result');
    if (!result.ok) {
      //console.log('error:', result.error);
    } else {
      //console.log('Data:', result.data);
      setData(result.data.result.client)
      setSampleStatus(result.data.result.sampleStatus)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])
  const [isDownloading, setIsDownloading] = useState(false)
  const handleDownload = async barcode => {
    setIsDownloading(true)
    const result = await apiClient.get(`report/download/${barcode}`)
    if (!result.ok) {
      toast.error("Error")
      setIsDownloading(false)
      return
    }
    await downloadFile(result.data.result)
    setIsDownloading(false)
  }
  const reportDataOld = useSelector(state => state.report.report)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleReportGenerate = (barcode, client) => {
    if (reportDataOld && reportDataOld.barcode !== barcode) {
      return dispatch(
        showModal({
          error: true,
          title: "Report",
          message: "Please finish report already in progress then you can generate another."
        })
      )
    } else navigate(`/tn/report/${barcode}?client=${client}`)
  }
  return (
    <div>
      <LoadingOverlay open={isDownloading} />
      <Grid container className="d-flex justify-content-center ">
        <Grid className="" item md={10} sx={{ backgroundColor: "" }} xs={12}>
          {/* <Button fullWidth className=' btn_bg_secondery py-3' variant='contained' sx={{whiteSpace: 'nowrap'}}>
        Pet Bio Feedback Center
        </Button> */}

          <Grid className=" d-flex justify-content-center align-items-center">
            <Grid container md={10} xs={12} spacing={2}>
              <Grid container className="mt-4" sx={{ py: 1 }}>
                <Grid className=" px-2 d-flex align-items-center" item md={5} xs={12}>
                  <div>
                    <BackButton to={"/tn/samples"} />
                  </div>
                  <Box className="ps-3 fw-bold " sx={{ display: "inline" }}>
                    Samples Details:
                  </Box>
                </Grid>
                {sampleStatus && sampleStatus !== "SUB" && (
                  <Grid className="px-2  d-flex justify-content-end" item md={7} xs={12}>
                    <Button
                      onClick={() => handleReportGenerate(data?.barcode, data?._id)}
                      className="m-2 btn btn_bg_secondery fs_10px same-size-button white_space"
                      variant="outlined"
                      color="primary">
                      Generate Report
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          {data?.veterinary_info && (
            <Grid className=" d-flex justify-content-center">
              <Grid
                className=" border"
                container
                md={10}
                xs={12}
                spacing={2}
                component={Paper}
                elevation={5}
                sx={{ marginTop: 1, padding: 5, borderRadius: 7 }}>
                <Box className=" text-start w-100">
                  <Typography
                    className="fw-bold "
                    variant="body2"
                    sx={{ fontSize: "1rem", textTransform: "uppercase" }}>
                    Veterinary Clinic Information:
                  </Typography>
                </Box>
                <Box mt={3}>
                  <hr style={{ backgroundColor: "red" }} />
                </Box>
                <Grid container sx={{ py: 2 }}>
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Clinic Name
                    </Typography>
                    <Typography className="fs_16px " variant="body2" sx={{}}>
                      {data?.veterinary_info.clinic_name}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Clinic Email
                    </Typography>
                    <Typography className="fs_16px " variant="body2" sx={{ fontSize: "0.7rem" }}>
                      {data?.veterinary_info.clinic_email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ py: 2 }}>
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Veterinarian
                    </Typography>
                    <Typography className="fs_16px " variant="body2" sx={{}}>
                      {data?.veterinary_info.veterinarian}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid className=" d-flex justify-content-center">
            <Grid
              className=" border"
              container
              md={10}
              xs={12}
              spacing={2}
              component={Paper}
              elevation={5}
              sx={{ marginTop: 1, padding: 5, borderRadius: 7 }}>
              <Box className=" text-start w-100">
                <Typography
                  className="fw-bold "
                  variant="body2"
                  sx={{ fontSize: "1rem", textTransform: "uppercase" }}>
                  Pet Parent Information:
                </Typography>
              </Box>
              <Box mt={3}>
                <hr style={{ backgroundColor: "red" }} />
              </Box>
              <Grid container sx={{ py: 2, rowGap: 6 }}>
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    First name
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.firstname}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Last name
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{ fontSize: "0.7rem" }}>
                    {data?.lastname}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Email
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.email}
                  </Typography>
                </Grid>
                {data?.email_2 && (
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                      2nd Email Recipient
                    </Typography>
                    <Typography className="fs_16px" variant="body2" sx={{}}>
                      {data?.email_2}
                    </Typography>
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Phone number
                  </Typography>
                  <Typography className=" fs_16px" variant="body2" sx={{}}>
                    {data?.phone_no}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Street Address
                  </Typography>
                  <Typography className="fs_16px" variant="body2" sx={{}}>
                    {data?.address}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className=" fs_16px" variant="body2" sx={{ fontWeight: "bold" }}>
                    Postal Code
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.zip}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    City
                  </Typography>
                  <Typography className="fs_16px" variant="body2" sx={{}}>
                    {data?.city}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className=" fs_16px" variant="body2" sx={{ fontWeight: "bold" }}>
                    State
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.state}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Country
                  </Typography>
                  <Typography className="fs_16px" variant="body2" sx={{}}>
                    {data?.country}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className=" d-flex justify-content-center">
            <Grid
              className=" "
              container
              md={10}
              xs={12}
              spacing={2}
              component={Paper}
              elevation={5}
              sx={{ marginTop: 5, padding: 5, borderRadius: 7 }}>
              <Box className="text-start w-100">
                <Typography
                  className="fw-bold "
                  variant="body2"
                  sx={{ fontSize: "1rem", textTransform: "uppercase" }}>
                  Pet Information:
                </Typography>
              </Box>
              <Box mt={3}>
                <hr style={{ backgroundColor: "red" }} />
              </Box>
              <Grid container sx={{ py: 2 }}>
                <Grid item md={4} xs={12}>
                  <Typography className="fs_16px" variant="body2" sx={{ fontWeight: "bold" }}>
                    Barcode
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.barcode}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Created Date
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{ fontSize: "0.7rem" }}>
                    {data?.createdAt ? new Date(data.createdAt).toISOString().split("T")[0] : ""}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ py: 2 }}>
                <Grid item md={4} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Pet name
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.pet?.pet_name}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  {/* <FormControlLabel control={<Checkbox  />} label="Dog"  sx={{
                     fontSize: '10px', // Adjust the font size as needed
                  }} /> */}
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Pet Type
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.pet?.type}
                    {/* uli */}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  {/* <FormControlLabel control={<Checkbox  />} label="Male" /> */}
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Gender
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.pet?.gender_value}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ py: 2 }}>
                <Grid item md={4} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Birthday
                  </Typography>
                  <Typography className="fs_16px " variant="body2">
                    {data?.pet?.dob}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Age
                  </Typography>
                  <Typography className="fs_16px " variant="body2">
                    {data?.pet?.age}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Breed
                  </Typography>
                  <Typography className="fs_16px " variant="body2">
                    {data?.pet?.breed}
                  </Typography>
                </Grid>
                <Grid sx={{ py: 2 }} item md={4} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Birth place{" "}
                  </Typography>
                  <Typography className="fs_16px " variant="body2">
                    {data?.pet?.birth_place}
                  </Typography>
                </Grid>
                {data?.notes_for_managers && (
                  <Grid sx={{ py: 2 }} item xs={12}>
                    <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Notes for Managers
                    </Typography>
                    <Typography className="fs_16px " variant="body2">
                      {data?.notes_for_managers}
                    </Typography>
                  </Grid>
                )}
                {/* <Grid sx={{py:2}} item md={4} xs={12}>
                <Typography className=' ' variant="body2" sx={{ fontSize: '1rem' }}>
                Medication             </Typography>
                <Typography className=' ' variant="body2" sx={{ fontSize: '0.7rem' }}>
                {data?.pet?.medication}
                </Typography>
                </Grid> */}
              </Grid>

              {/* <Typography className='text_color ' variant="body2" sx={{ fontSize: '1rem', textTransform:'uppercase' }}>
                 Pet diet:
                </Typography> */}
              {/* <Grid container sx={{py:2}}>
                <Grid item md={6} xs={12}>
                <FormControlLabel control={<Checkbox  />} label="Dry Kibble:" />
                <FormControlLabel control={<Checkbox  />} label="Raw:" />
                <FormControlLabel control={<Checkbox  />} label="Home Cooked :" />
                </Grid>
             </Grid> */}
              <Typography className=" fs_16px" variant="body2" sx={{}}>
                Agreed With Terms & Conditions.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default ClientPetBio
