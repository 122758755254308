import { CircularProgress, FormHelperText, Grid, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import apiClient from "../../api/apiClient"
import { useOutletContext } from "react-router-dom"
const Settings = ({ role = "AD" }) => {
  const [load, setLoad] = useState(false)
  const [modalShow, setModalShow] = React.useState(false)
  const { setRefresh, user } = useOutletContext()
  // console.log(user,"lllll");
  let initialState = {
    sample_receive_days: "",
    est_date_exp: "",
    est_date_pp: "",
    est_date_regular: ""
  }
  const [formData, setFormData] = useState(initialState)
  // console.log(profile,"lllll");

  useEffect(() => {
    fetchSettings()
  }, [])

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value })
  }

  const fetchSettings = async () => {
    const result = await apiClient.get("/settings")
    if (!result.ok) {
      setFormData({
        sample_receive_days: 0,
        est_date_exp: 0,
        est_date_pp: 0,
        est_date_regular: 0
      })
      return
    }

    const responseData = result.data.result
    setFormData({
      sample_receive_days: responseData.sample_receive_days,
      est_date_pp: responseData.estimated_processing_days.pp_type,
      est_date_exp: responseData.estimated_processing_days.exp_type,
      est_date_regular: responseData.estimated_processing_days.regular
    })
  }

  const handleSubmit = async e => {
    setLoad(true)
    e.preventDefault()
    const formDataObj = {
      sample_receive_days: formData.sample_receive_days,
      estimated_processing_days: {
        pp_type: formData.est_date_pp,
        exp_type: formData.est_date_exp,
        regular: formData.est_date_regular
      }
    }
    const result = await apiClient.patch("/settings", formDataObj)
    setLoad(false)
    setRefresh(true)
    // console.log(result);
  }
  return (
    <div>
      <Grid container className=" d-flex justify-content-center " spacing={2}>
        <Grid item md={10} sx={{ backgroundColor: "" }} xs={12}>
          <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <h3 className="mb-0">App Global Settings:</h3>
          </div>
        </Grid>
      </Grid>

      <div className="d-flex justify-content-center">
        <div className="col-md-8  col-lg-3 ">
          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-center">
              <div className="col-md-12 p-3 ">
                <div>
                  <h6>Report Processing Dates</h6>
                  <div className="px-1 py-3">
                    <FormHelperText>
                      Enter the number of days for Report processing date . e.g 14 Days
                    </FormHelperText>
                    <FormHelperText>Enter 0 days to disable this feature.</FormHelperText>
                  </div>
                  <div class="equal-width py-1">
                    <TextField
                      // required
                      label="Standard"
                      type="number" // Accepts only numbers
                      value={formData.est_date_regular}
                      onChange={e => handleChange("est_date_regular", e.target.value)}
                      variant="outlined"
                      fullWidth
                      // helperText="Enter the number of days . e.g 14 Days"
                    />
                  </div>
                  <div class="equal-width py-1">
                    <TextField
                      // required
                      label="Expedite EXP"
                      type="number" // Accepts only numbers
                      value={formData.est_date_exp}
                      onChange={e => handleChange("est_date_exp", e.target.value)}
                      variant="outlined"
                      fullWidth
                      // helperText="Enter the number of days . e.g 14 Days"
                    />
                  </div>
                  <div class="equal-width py-1">
                    <TextField
                      // required
                      label="PP"
                      type="number" // Accepts only numbers
                      value={formData.est_date_pp}
                      onChange={e => handleChange("est_date_pp", e.target.value)}
                      variant="outlined"
                      fullWidth
                      // helperText="Enter the number of days . e.g 14 Days"
                    />
                  </div>
                  <hr class="mt-2 mb-2" />
                  <div class="equal-width mt-3">
                    <TextField
                      //  required
                      label="Sample Not-Received Date"
                      type="number" // Accepts only numbers
                      value={formData.sample_receive_days}
                      onChange={e => handleChange("sample_receive_days", e.target.value)}
                      variant="outlined"
                      fullWidth
                      // helperText="Enter the number of days . e.g 14 Days"
                    />
                    <div className="px-1">
                      <FormHelperText>
                        Enter the number of days after which client should <br /> receive email that
                        sample haven't been received . e.g 14 Days
                      </FormHelperText>
                      <FormHelperText>Enter 0 days to disable this feature.</FormHelperText>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            {!load ? (
              <div className="d-flex justify-content-center px-3">
                <button type="submit" class="btn btn_bg_secondery  px-5 text-white">
                  Save Settings
                </button>
              </div>
            ) : (
              <div className=" w-100 d-flex justify-content-center align-items-center">
                <CircularProgress />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Settings
