import React, { useEffect, useState } from "react"
import Webnav from "../../components/Webnav"
import BackIcon from "@mui/icons-material/ArrowBack"
import { useNavigate, useParams } from "react-router-dom"
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material"
import BackButton from "../../components/mui/BackButton"
import apiClient from "../../api/apiClient"
import { ToastContainer, toast } from "react-toastify"
import { useLocation } from "react-router-dom"
import CircularProgress from "@mui/material/CircularProgress"
import Modal from "react-bootstrap/Modal"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { showModal } from "../../redux/modalSlice"
import { useDispatch } from "react-redux"
import { runValidations } from "../../modules/yupHelpers"
import * as Yup from "yup"
import FieldErrorMessage from "../../components/FieldErrorMessage"
import { copyRightYear, formatDate } from "../../modules/helpers"
import SelectOption from "../../components/mui/SelectOption"
const PetBioRegistration = () => {
  const [show, setShowModal] = useState(false)
  const labelStyle = {
    fontSize: "10px"
  }
  const [load, setLoad] = useState(false)
  const location = useLocation()

  const { id } = useParams()
  const data = id
  let petobject = {
    pet_name: "",
    type: "",
    gender: "",
    dob: "",
    age: "",
    breed: "",
    birth_place: "",
    medication: "",
    remarks: ""
  }
  let initialstate = {
    firstname: "",
    lastname: "",
    address: "",
    state: "",
    city: "",
    zip: "",
    country: "",
    phone_no: "",
    email: "",
    email_2: "",
    barcode: data,
    clinic_email: "",
    clinic_name: "",
    veterinarian: "",
    veterinarian_title: "",
    tracking_no: ""
  }
  const dispatch = useDispatch()
  const [client, setClient] = useState(initialstate)
  const [pet, setPet] = useState(petobject)
  const [isChecked, setIsChecked] = useState(false)

  const handelCheckboxChange = e => {
    setIsChecked(e.target.checked)
  }

  // const handleChange = (key, value) => {
  //   const keys = key.split('.');
  //   setPet((prevState) => {
  //     const updatedPet = { ...prevState.pet };
  //     let currentObj = updatedPet;

  //     for (let i = 0; i < keys.length - 1; i++) {
  //       const currentKey = keys[i];
  //       currentObj[currentKey] = { ...currentObj[currentKey] };
  //       currentObj = currentObj[currentKey];
  //     }

  //     currentObj[keys[keys.length - 1]] = value;

  //     return { ...prevState, pet: updatedPet };
  //   });
  // };
  const handleChange = (key, value) => {
    setClient({ ...client, [key]: value })
  }
  const handleChange1 = (key, value) => {
    setPet({ ...pet, [key]: value })
  }

  // const navigate = useNavigate();
  // const handleSubmit=async(e)=>{
  //   e.preventDefault()
  //   setLoad(true);
  //   //console.log(pet);
  //   //console.log(client);
  //   const data={...client,pet:pet}
  //   //console.log(data);
  //   const result=await apiClient.post("/client/",data)
  //   setLoad(false);
  //   //console.log(result);
  //   toast.success('Data saved successfully!')
  //   // navigate(`/admin/pet-bio/${result.data._id}`);
  // }

  const [isPPMode] = useState(data?.toLowerCase()?.includes("pp"))
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required().label("Firstname"),
    lastname: Yup.string().required().label("Lastname"),
    address: Yup.string().required().label("Street Address"),
    state: Yup.string().required().label("State"),
    city: Yup.string().required().label("City"),
    country: Yup.string().required().label("Country"),
    zip: Yup.string().required().label("Postal Code"),
    phone_no: Yup.string().required().label("Phone Number"),
    email: Yup.string().email("Invalid email format").required().label("Email"),
    ...(!isPPMode && {
      confirm_email: Yup.string()
        .email("Invalid email format")
        .required()
        .oneOf([Yup.ref("email"), null], "Emails must match")
        .label("Confirm Email")
    }),
    email_2: Yup.string().email().label("Email"),
    barcode: Yup.string().required().label("Barcode"),
    pet: Yup.object()
      .shape({
        pet_name: Yup.string().required().label("Pet Name"),
        type: Yup.string().required("This box must be selected to continue.").label("Pet Type"),
        gender: Yup.string().required("This box must be selected to continue.").label("Pet Gender"),
        dob: Yup.date()
          .nullable()
          .default(null)
          .label("Date of Birth")
          .typeError("Please enter a valid date"),
        age: Yup.string().required().label("Age"),
        breed: Yup.string().required().label("Breed"),
        birth_place: Yup.string().label("Birth Place"),
        medication: Yup.string().label("Medication"),
        remarks: Yup.string().label("Remarks")
      })
      .required(),
    ...(isPPMode
      ? {
          veterinarian: Yup.string().required().label("Veterinarian"),
          clinic_email: Yup.string().required().label("Clinic Email"),
          clinic_name: Yup.string().required().label("Clinic Name"),
          veterinarian_title: Yup.string().required().label("Veterinarian Title")
        }
      : {})
  })
  const [errors, setErrors] = useState({})
  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      var scrollDiv = document.getElementById("my-form")

      // Find the first element with the class "error" inside the "my-form" div
      var errorElement = scrollDiv.querySelector(".error")

      if (errorElement) {
        // Scroll to the top of the error element
        window.scrollTo({ top: errorElement.offsetTop - 200, behavior: "smooth" })
      }
    }
  }, [errors])
  const handleSubmit = async e => {
    e.preventDefault()
    let dataCombined = { ...client, pet: pet }

    if (isPPMode) {
      dataCombined.email = dataCombined.clinic_email
    }
    const { success, validationErrors } = runValidations(validationSchema, dataCombined)
    // console.log(success, "ytckgtucgktckgy")
    // console.log(validationErrors,"step")
    if (!success) {
      return setErrors(validationErrors)
    }
    let { veterinarian, clinic_email, clinic_name, veterinarian_title, ...data } = dataCombined
    if (isPPMode) {
      data.veterinary_info = {
        veterinarian,
        clinic_email,
        clinic_name,
        veterinarian_title
      }
    }
    setLoad(true)
    //console.log(data);

    if (data) {
      try {
        const result = await apiClient.post("/client/", data)
        //console.log(result);
        if (!result.ok) {
          dispatch(
            showModal({
              error: true,
              title: "Client",
              message: result?.data?.message
            })
          )
          setLoad(false) // Stop the loading state
          return // Exit the function to prevent further execution
        }
        dispatch(
          showModal({
            title: "Client",
            onBack,
            message: data?.barcode?.includes("pp")
              ? "PP Pet Test data has been Submitted!"
              : "Pet Test data has been Submitted!"
          })
        )
        setLoad(false)
        // navigate(`/admin/pet-bio/${result.data._id}`);
      } catch (error) {
        //console.log(error);
        setLoad(false)
        toast.error("Error saving data!")
      }
    } else {
      setLoad(false)
      toast.error("No data to save!")
    }
  }
  const navigate = useNavigate()
  const onBack = () => {
    navigate(-1)
  }

  const ageDataArr = [
    { value: "Under 6 months", label: "Under 6 months" }, // Adding months option first
    { value: "6-12 months", label: "6-12 months" }, // Adding months option first
    //age from 1 to 24 years
    ...Array.from({ length: 24 }, (_, i) => ({
      value: `${i + 1} year${i > 0 ? "s" : ""}`,
      label: `${i + 1} year${i > 0 ? "s" : ""}`
    })),
    { value: "25+ years", label: "25+ years" } // Adding the last option
  ]
  return (
    <div>
      <Webnav />

      <Box
        className="shadow-sm "
        sx={{
          // backgroundColor: '#8C7F7F',
          height: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "5rem",
          padding: "0 1rem",
          "@media (max-width: 600px)": {
            height: "auto",
            marginTop: "5rem"
          }
        }}>
        <img className="" src={require("../../assets/logo-new2.png")} alt="" />
      </Box>

      <Box className=" d-flex justify-content-center">
        <Grid item container className=" py-2" sx={{ paddingLeft: 4.5 }} xs={12} md={10}>
          <BackButton to={"/"} />
        </Grid>
      </Box>

      <Grid container justifyContent="center" id="my-form">
        <Grid item xs={12} md={10}>
          <Paper aper elevation={5} sx={{ padding: "2rem", marginTop: "2rem" }}>
            <Button
              fullWidth
              className="btn_bg_light fw-bold py-3"
              variant="contained"
              sx={{ whiteSpace: "nowrap" }}>
              Glacier Peak Holistics
            </Button>
            <Grid container className="" sx={{ py: 2 }}>
              <Grid className="d-flex justify-content-center" item md={5} xs={12}>
                <Box>
                  <img src={require("../../assets/GPH Logo C_3 animals-01.png")} alt="" />
                </Box>
                <Box className="ps-3 fw-bold " sx={{ display: "inline" }}>
                  {/* Pet Bio <br /> Feedback Center */}
                  Glacier Peak Pet <br />
                  Intolerance Test
                </Box>
              </Grid>
              <Grid className="d-flex justify-content-center" item md={7} xs={12}>
                <Typography
                  className=""
                  sx={{ textTransform: "uppercase" }}
                  variant="p"
                  color="initial">
                  Information Sheet
                </Typography>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit} noValidate>
              <div className="d-flex justify-content-end">
                <span>
                  <span style={{ color: "red" }}>*</span> Fields marked with an asterisk are
                  mandatory.
                </span>
              </div>
              {isPPMode && (
                <Box
                  sx={{
                    border: "1px solid #CCC",
                    marginTop: "1rem",
                    borderRadius: "1rem",
                    padding: 2
                  }}>
                  <Box className="text-start w-100 mb-3">
                    <Typography
                      className="text_color"
                      variant="body2"
                      sx={{ fontSize: "1rem", textTransform: "uppercase" }}>
                      Veterinary Clinic Information:
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
                        <SelectOption
                          style={{ width: "100%" }}
                          data={[
                            { value: "Owner", label: "Owner" },
                            { value: "Veterinarian", label: "Veterinarian" },
                            { value: "Pet Health Coach", label: "Pet Health Coach" },
                            { value: "Other", label: "Other" }
                          ]}
                          id="outlined-basic"
                          value={client.veterinarian_title}
                          onChange={e => handleChange("veterinarian_title", e.target.value)}
                          label="Address As"
                          variant="outlined"
                        />
                      </Box>
                      <FieldErrorMessage errors={errors["veterinarian_title"]} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        required
                        label="Veterinarian"
                        value={client.veterinarian}
                        onChange={e => handleChange("veterinarian", e.target.value)}
                        variant="outlined"
                        fullWidth
                      />
                      <FieldErrorMessage errors={errors["veterinarian"]} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        required
                        label="Clinic Name"
                        value={client.clinic_name}
                        onChange={e => handleChange("clinic_name", e.target.value)}
                        variant="outlined"
                        fullWidth
                      />
                      <FieldErrorMessage errors={errors["clinic_name"]} />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        required
                        label="Clinic Email"
                        value={client.clinic_email}
                        onChange={e => handleChange("clinic_email", e.target.value)}
                        variant="outlined"
                        fullWidth
                      />
                      <FieldErrorMessage errors={errors["clinic_email"]} />
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Box
                sx={{
                  border: "1px solid #CCC",
                  marginTop: "1rem",
                  borderRadius: "1rem",
                  padding: 2
                }}>
                <Box className="text-start w-100 mb-3">
                  <Typography
                    className="text_color"
                    variant="body2"
                    sx={{ fontSize: "1rem", textTransform: "uppercase" }}>
                    Pet Parent Information:
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      label="First Name"
                      value={client.firstname}
                      onChange={e => handleChange("firstname", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["firstname"]} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      label="Last Name"
                      value={client.lastname}
                      onChange={e => handleChange("lastname", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["lastname"]} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      required={true}
                      label="State"
                      value={client.state}
                      onChange={e => handleChange("state", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["state"]} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      required={true}
                      label="City"
                      value={client.city}
                      onChange={e => handleChange("city", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["city"]} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      label="Country"
                      value={client.country}
                      onChange={e => handleChange("country", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["country"]} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      label="Phone Number"
                      value={client.phone_no}
                      onChange={e => handleChange("phone_no", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["phone_no"]} />
                  </Grid>
                  {!isPPMode && (
                    <>
                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          label="Email"
                          value={client.email}
                          onChange={e => handleChange("email", e.target.value)}
                          variant="outlined"
                          fullWidth
                        />
                        <FieldErrorMessage errors={errors["email"]} />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          label="Confirm Email"
                          value={client.confirm_email}
                          onChange={e => handleChange("confirm_email", e.target.value)}
                          variant="outlined"
                          fullWidth
                        />
                        <FieldErrorMessage errors={errors["confirm_email"]} />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="2nd email recipient (optional)"
                      value={client.email_2}
                      onChange={e => handleChange("email_2", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["email_2"]} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Tracking Number (optional)"
                      value={client.tracking_no}
                      onChange={e => handleChange("tracking_no", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["tracking_no"]} />
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ marginTop: 3 }}>
                <Box className="text-start w-100 mb-3">
                  <Typography
                    className="text_color"
                    variant="body2"
                    sx={{ fontSize: "1rem", textTransform: "uppercase" }}>
                    Pet Information:
                  </Typography>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      required={true}
                      label="Pet Firstname and Lastname"
                      value={pet.pet_name}
                      onChange={e => handleChange1("pet_name", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["pet.pet_name"]} />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
                      {/* <FormLabel id="pet-type" required>
                        Pet Type:{" "}
                      </FormLabel>
                      <FormControl>
                        <RadioGroup
                          row
                          // name="nameRadio" value={''}
                          onChange={e => handleChange1("type", e.target.value)}
                          checked={pet.type}>
                          <FormControlLabel value="dog" control={<Radio />} label="Dog" />
                          <FormControlLabel value="cat" control={<Radio />} label="Cat" />
                        </RadioGroup>
                      </FormControl> */}
                      <SelectOption
                        style={{ width: "100%" }}
                        data={[
                          { value: "dog", label: "Dog" },
                          { value: "cat", label: "Cat" },
                          { value: "equine", label: "Equine" }
                        ]}
                        id="outlined-basic"
                        value={pet.type}
                        onChange={e => handleChange1("type", e.target.value)}
                        label="Pet Type"
                        variant="outlined"
                      />
                    </Box>
                    <FieldErrorMessage errors={errors["pet.type"]} />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
                      <FormLabel id="gender" required>
                        Gender:{" "}
                      </FormLabel>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="gender"
                          name="radio-buttons-group"
                          row
                          onChange={e => handleChange1("gender", e.target.value)}
                          checked={pet.gender}>
                          <FormControlLabel value="M" control={<Radio />} label="Male" />
                          <FormControlLabel value="F" control={<Radio />} label="Female" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <FieldErrorMessage errors={errors["pet.gender"]} />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      helperText="Pet Date of birth if known, otherwise put todays date."
                      InputLabelProps={{ shrink: true }}
                      required
                      type="date"
                      label="Pet Birthday"
                      value={pet.dob}
                      onChange={e => handleChange1("dob", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />

                    <FieldErrorMessage errors={errors["pet.dob"]} />
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                    <TextField
                      required
                      label="Age"
                      type="number"
                      value={pet.age}
                      onChange={e => handleChange1("age", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["pet.age"]} />
                  </Grid> */}
                  <Grid item xs={12} md={4}>
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
                      <SelectOption
                        style={{ width: "100%" }}
                        data={ageDataArr}
                        id="outlined-basic"
                        value={pet.age}
                        onChange={e => handleChange1("age", e.target.value)}
                        label="Pet Age"
                        variant="outlined"
                      />
                    </Box>
                    <FieldErrorMessage errors={errors["pet.type"]} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      label="Breed"
                      value={pet.breed}
                      onChange={e => handleChange1("breed", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["pet.breed"]} />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      helperText="If known"
                      label="Birth Place"
                      value={pet.birth_place}
                      onChange={e => handleChange1("birth_place", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["pet.birth_place"]} />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      label="Pet Street Address"
                      value={client.address}
                      onChange={e => handleChange("address", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["address"]} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      label="Pet Postal Code"
                      value={client.zip}
                      onChange={e => handleChange("zip", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <FieldErrorMessage errors={errors["zip"]} />
                  </Grid>

                  {/* <Grid item xs={12} md={4}>
                          
                                      
                          <TextField
                          helperText="Add any additional remarks or notes here. 80 characters max."
                         label="Remarks"
                           value={pet.remarks}
                           inputProps={{maxLength:80}}
                          onChange={(e) => handleChange1("remarks", e.target.value)}
                          variant="outlined"
                              fullWidth
                          /> 
                            <FieldErrorMessage errors={errors["pet.remarks"]}/>
                         </Grid> */}

                  {/* <Grid item xs={12} md={4}>
                            <TextField
                            helperText="Please provide the name of any medications your pet is currently on."
                              label="Medication" value={pet.medication} onChange={(e)=>handleChange1("medication",e.target.value)} variant="outlined" fullWidth />
                          </Grid> */}
                </Grid>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12} md={4}>
                                    <TextField
                                      helperText="Please provide the name of any medications your pet is currently on. 300 characters max."
                                      label="Medication"
                                      value={pet.medication}
                                      onChange={(e) => handleChange1("medication", e.target.value)}
                                      variant="outlined"
                                      fullWidth
                                  inputProps={{maxLength:300}}
                                      rows={4}
                                      multiline
                                    />
                                      
                                      <FieldErrorMessage errors={errors["pet.medication"]}/>
                                    </Grid> */}
                </Grid>
                <Box className="text-start w-100 mb-3" sx={{ marginTop: "1rem" }}>
                  <Typography
                    className="text_color"
                    variant="body2"
                    sx={{ fontSize: "1rem", textTransform: "uppercase" }}>
                    BARCODE:
                  </Typography>
                </Box>

                <Grid item xs={12} md={4}>
                  <TextField
                    label="Barcode No"
                    value={client.barcode}
                    disabled
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Box className="text-start w-100 mb-3" sx={{ marginTop: "1rem" }}>
                  <Typography
                    className="text_color"
                    variant="body2"
                    sx={{ fontSize: "1rem", textTransform: "uppercase" }}>
                    TERMS & CONDITIONS:
                  </Typography>
                </Box>

                <Box
                  sx={{
                    height: "30vh",
                    border: "1px solid #CCC",
                    overflow: "auto",
                    padding: "1rem"
                  }}>
                  <p>
                    The primary goal of the completely non-invasive Bioelectric Feedback Analysis
                    provided by Glacier Peak Holistics is to identify stressors that can impact the
                    overall wellbeing of your pet. Evidence-gathering techniques combine ancient
                    Chinese health traditions and holistic methodologies with modern electronic
                    tools to enable comprehensive assessments and analytics that will allow the
                    undersigned pet Guardian to make choices that benefit the living environment and
                    overall diet of the Guardian’s pet. Our trained Bioelectric Feedback Analysis
                    Technicians do not diagnose, cure, mitigate, treat or prevent any disease or
                    health conditions and the analysis report does not function in any way to
                    provide any service or function reserved exclusively for licensed{" "}
                    <span style={{ fontStyle: "italic" }}>Veterinary</span> medicine practitioners.
                    Although the reports generated by our technical staff should not be used by the
                    Guardian as a basis to diagnose, treat, mitigate or prevent disease, we will
                    provide the pet’s <span style={{ fontStyle: "italic" }}>veterinarian</span> with
                    a copy of the report upon the Guardian’s request. By requesting that a
                    Bioelectric Feedback Analysis be conducted for the Guardian’s pet in exchange
                    for the applicable service fee and signing this Intake Form and Agreement, the
                    undersigned Guardian expressly acknowledges and agrees that neither Glacier Peak
                    Holistics nor its principals, employees and/or technicians are diagnosing,
                    treating, curing, preventing, or prescribing any substance whatsoever for any
                    animal disease or health condition. Guardian further agrees that disease
                    diagnosis, treatment or prescription is not offered or intended by Glacier Peak
                    Holistics, that Guardian will seek only the advice of a licensed{" "}
                    <span style={{ fontStyle: "italic" }}> veterinarian </span> to diagnose or treat
                    all his or her pet’s symptoms or diseases and as follows: Applicable Law;
                    Construction: This Intake Form and Agreement is governed by and will be
                    construed in accordance with the laws of the State of Montana without regard to
                    any conflict of laws, rule, or principle that might refer the governance or
                    construction of this Intake Form and Agreement to laws of another jurisdiction.
                    This Intake Form and Agreement will at all times and in all events be construed
                    as a whole, according to its fair meaning of its terms, and not strictly for or
                    against any party. Parties Bound: This Intake Form and Agreement will be binding
                    upon, and inure to the benefit of each of the parties hereto to the extent
                    applicable to them and their respective successors and assigns. All records are
                    held in confidence and can be released to a third party only with the express,
                    written permission of the other. Mutual Understanding: Each party has read this
                    entire Intake Form and Agreement, fully understands and agrees to be
                    contractually bound by its contents, has had the opportunity to obtain
                    independent advice as to its legal effect, and is under no duress or obligation
                    of any kind to execute it. Free and Informed Choice: The Pet’s Guardian
                    understands that the Bioelectric Feedback Analysis service has been sought by
                    his/her free, personal and informed choice, hereby provides his/her express
                    consent to the Bioelectric Feedback Analysis service and agrees to all the terms
                    and conditions herein.
                  </p>
                </Box>
                <Box>
                  <div className="py-2 d-flex align-items-center gap-2">
                    <input
                      type="checkbox"
                      name="terms"
                      id="terms"
                      checked={isChecked}
                      onChange={handelCheckboxChange}
                    />
                    <label htmlFor="terms">I agree with terms and conditions</label>
                  </div>

                  {!load ? (
                    <>
                      <Button
                        className="btn_bg_secondery mt-2"
                        variant="contained"
                        type="submit"
                        disabled={!isChecked}
                        sx={{ whiteSpace: "nowrap", borderRadius: "20px", px: 4 }}>
                        Submit
                      </Button>

                      {!isChecked && (
                        <p style={{ color: "red" }}>
                          Please agree with the terms and conditions to proceed.
                        </p>
                      )}
                    </>
                  ) : (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
      <Box
        className=""
        sx={{
          backgroundColor: "#3E4755",
          height: "200px",
          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'center',
          marginTop: "5rem",
          padding: "0 1rem",
          "@media (max-width: 600px)": {
            height: "auto",
            marginTop: "5rem"
          }
        }}>
        <Typography
          className="noto_font"
          variant="h4"
          color="white"
          align="center"
          sx={{ margin: "1rem" }}>
          GLACIER PEAK HOLISTICS <br /> TEST REGISTRATION
        </Typography>
        <p className="text-center text-white mb-0" style={{ backgroundColor: "#3E4755" }}>
          &copy;{copyRightYear()} GLACIER PEAK HOLISTICS TEST REGISTRATION All Rights Reserved.
        </p>
      </Box>
      <Box
        sx={{
          backgroundColor: "#3E4755",
          padding: "1rem",
          textAlign: "center",
          color: "white",
          letterSpacing: "20px"
        }}>
        <Typography className="txet-muted" variant="body2" color="inherit">
          All rights reserved Maintained & Developed by
          <span> </span>
          <a href="https://fabtechsol.com" target="_blank" rel="noopener noreferrer">
            fabtechsol
          </a>
        </Typography>
      </Box>
    </div>
  )
}

export default PetBioRegistration
// function NewSampelModal(props) {

//   const navigate = useNavigate();

//   const handleBackAndClose = () => {
//     navigate(-1)
//     props.onHide();
//   };
//   return (
//     <Modal
//       {...props}
//       onHide={handleBackAndClose}
//       size="sm"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header className=' '>
//         <Modal.Title id="contained-modal-title-vcenter" className=' text-center w-100'>
//         <CheckCircleIcon style={{ fontSize: '50px', color: '#3E4755' }} />
//         </Modal.Title>
//         <button className="close test" onClick={handleBackAndClose}>
//           <span aria-hidden="true">&times;</span>
//         </button>
//       </Modal.Header>
//       <Modal.Body>
//         <h4 className='text-center'>
//           Client data
//         </h4>
//         <p className='text-center'>
//         {/* Report is sucessfully subbmited to admin and email sent to the client */}
//         Client Data Saved sucessfully.
//         </p>
//       </Modal.Body>

//     </Modal>
//   );
// }
