import { Button, Grid } from "@mui/material"
import { Box } from "@mui/system"
import Modal from "react-bootstrap/Modal"
import React, { useEffect, useState } from "react"
import { Products, ReportProducts } from "../../components/Products"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { useLocation, useParams } from "react-router-dom"
import useApi from "../../hooks/useApi"
import apiClient from "../../api/apiClient"
import { toast } from "react-toastify"
import { addReportData, removeReportData } from "../../redux/reportSlice"
import { useDispatch, useSelector } from "react-redux"
import { showModal } from "../../redux/modalSlice"
import { useNavigate } from "react-router-dom"
import BackButton from "../../components/mui/BackButton"
import { isGreenCategory } from "./CreateReport"
import _ from "lodash"

const STATUS = {
  CHECKED: "checked",
  UNCHECKED: "unchecked"
}
// Helper function to process a product
const processProduct = (product, petType, productSelectedArray) => {
  const { product: productName, product_recommended, pet_type } = product

  if (pet_type === "" || pet_type === petType) {
    const currentIndex = productSelectedArray.findIndex(x => x.product === productName)
    const newStatus = product_recommended ? STATUS.CHECKED : STATUS.UNCHECKED

    if (currentIndex === -1) {
      // Add product if it doesn't exist
      productSelectedArray.push({ product: productName, status: newStatus })
    } else if (newStatus === STATUS.UNCHECKED) {
      // Update status if it's unchecked
      productSelectedArray[currentIndex].status = newStatus
    }
  }
}
const processPetTypeProduct = (product, petType, productSelectedArray) => {
  const { product: productName, product_recommended, pet_type } = product

  if (pet_type === "" || pet_type === petType) {
    const currentIndex = productSelectedArray.findIndex(x => x.product === productName)
    const newStatus = product_recommended ? STATUS.CHECKED : STATUS.UNCHECKED

    if (currentIndex === -1) {
      productSelectedArray.push({ product: productName, status: newStatus })
    } else {
      // Force override existing status regardless of previous value
      productSelectedArray[currentIndex].status = newStatus
    }
  }
}

const CreateReportPreview = () => {
  const [selectd, setSelected] = useState([])
  const [show, setShowModal] = useState(false)
  const [selectedText, setSelectedText] = useState("")
  const { barcode } = useParams()
  const reportDataOld = useSelector(state => state.report.report)
  const [products, setProducts] = useState(reportDataOld?.products || [])
  const [gridData, setGridData] = useState(reportDataOld?.gridData || [])
  const dispatch = useDispatch()
  const [productsIfThenData, setProductIfThen] = useState([])
  const [engRefProductsData, setEngRefProduct] = useState([])
  console.log(reportDataOld, "old data")
  useEffect(() => {
    if (products.length > 0) dispatch(addReportData({ ...reportDataOld, products }))
  }, [products])

  useEffect(() => {
    // Exit early if there is no product data or if the report stage is "preview"
    if (productsIfThenData.length === 0 || reportDataOld.report_stage === "preview") return

    // Extract the selected items, pet type, and energetic imbalances from the data
    const selectedItems = reportDataOld?.selected || []
    let petType = reportDataOld?.clData?.pet?.gender // Pet type (gender) for filtering products
    const productSelectedArray = [] // Array to store selected products and their statuses
    const energetic_imbalances = state?.imbalance || [] // List of energetic imbalances
    /*
    priority of status

    unchecked - can't override
    checked - can overridden by uncheck

    pet type if then has highest priority and it can override by data by previous if then 


    */

    // Process products based on selected items
    selectedItems.forEach(itemEl => {
      const filteredProducts = productsIfThenData.filter(x => x.itemId === itemEl.id)
      filteredProducts.forEach(productEl => {
        if (productEl.pet_type === "" || productEl.pet_type === petType) {
          if (itemEl.senstivity === productEl.item_senstivity) {
            processProduct(productEl, petType, productSelectedArray)
          }
        }
      })
    })

    // Process products for energetic imbalances
    energetic_imbalances.forEach(itemEl => {
      const filteredProducts = engRefProductsData.filter(x => x.energetic_imbalance === itemEl)
      filteredProducts.forEach(productEl =>
        processProduct(productEl, petType, productSelectedArray)
      )
    })

    // Process pet-based products
    const filteredProductsPetBased = productsIfThenData.filter(x => !x.itemId)
    filteredProductsPetBased.forEach(productEl =>
      processPetTypeProduct(productEl, petType, productSelectedArray)
    )

    // The resulting `productSelectedArray` now contains the list of products with their statuses

    console.log(productSelectedArray, "added products")

    setProducts(productSelectedArray)
    dispatch(addReportData({ ...reportDataOld, products, report_stage: "preview" }))
  }, [productsIfThenData])
  console.log(products)
  useEffect(() => {
    fetchifthenProductData()
    fetchEngRefProductData()
  }, [])
  const fetchifthenProductData = async () => {
    const result = await apiClient.get("/if-then/products/?limit=100000000")
    if (!result.ok) return console.log("error")

    setProductIfThen(result.data.results)
  }
  const fetchEngRefProductData = async () => {
    const result = await apiClient.get("/if-then/energetic/?limit=100000000")
    if (!result.ok) return console.log("error")

    setEngRefProduct(result.data.results)
  }
  function handleProducts(product) {
    let updatedData = [...products]
    const status = updatedData?.find(x => x.product === product)?.status
    if (!status) updatedData.push({ status: "checked", product })
    else if (status === "checked") {
      updatedData = updatedData.map(item => {
        if (item.product === product) return { ...item, status: "unchecked" }

        // item.status="unchecked"
        return item
      })
    } else if (status === "unchecked") {
      updatedData = [...updatedData.filter(x => x.product !== product)]
    }

    console.log(updatedData)
    setProducts(updatedData)
  }
  const handleTextClick = text => {
    setSelectedText(text)
  }
  const { state } = useLocation()
  const { data, imbalance, date } = state
  //console.log(imbalance,"imabalance");

  const { request } = useApi(data => apiClient.post("/report", data))
  async function handleSubmit() {
    let items = []
    for (const item of data) {
      for (const subItem of item.items) {
        const { _id: id, senstivity } = subItem
        items.push({ id, senstivity })
      }
    }
    const reportData = {
      barcode,
      report_created_date: date,
      items,
      products,
      energetic_imbalance: imbalance
    }
    //console.log(reportData,"klklkl");

    const result = await request(reportData)
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Report Submittion Failed",
          message: result.data.message
        })
      )
    dispatch(
      showModal({
        title: "Report Submitted , Email sent",
        onBack,
        message: "Report is sucessfully submitted to admin and email sent to the client."
      })
    )
    dispatch(removeReportData())
  }
  const navigate = useNavigate()
  const onBack = () => {
    navigate("/tn/dashboard")
  }

  function combineWithSensitivity(rawData, selectedData) {
    let combinedData = rawData.map(categoryItem => {
      let combinedCategory = {
        ...categoryItem,
        items: categoryItem.items.map(item => {
          let isMatched = selectedData.some(subItem =>
            subItem.items.some(reportItem => reportItem._id === item._id)
          )
          if (isMatched) {
            let matchedSubItem = selectedData.find(subItem =>
              subItem.items.some(reportItem => reportItem._id === item._id)
            )
            let matchedItem = matchedSubItem.items.find(reportItem => reportItem._id === item._id)
            return {
              ...item,
              senstivity: matchedItem.senstivity
            }
          }
          return item
        })
      }
      return combinedCategory
    })

    return combinedData
  }

  const [alldata, setAllData] = useState()

  //   const dataCombined = combineWithSensitivity(gridData, data);
  //  setAllData(dataCombined)
  //  console.log(dataCombined);

  useEffect(() => {
    // Call the combineWithSensitivity function here
    const combinedWithSensitivity = combineWithSensitivity(gridData, data)
    setAllData(combinedWithSensitivity)
  }, [])
  //console.log(alldata,"allll")
  const fetchData = async () => {
    const result = await apiClient.get("/report/raw-data/")
    if (!result.ok) return
    //console.log(result.data, "SDDSADS");

    const data = result.data.slice() // Create a shallow copy of the original array
    const lastItem = data.pop() // Remove the last item from the copied array
    data.unshift(lastItem) // Add the last item to the first index of the copied array

    setGridData(data) // Set the state with the updated array

    // //  First fetch request
    //  const result2 = await apiClient.get(`/client/${id}`);
    //  if (!result2.ok) return;
    //  //console.log(result2.data, 'client data');
  }

  useEffect(() => {
    fetchData()
  }, [])

  function findInGridDataByName(items, sign) {
    const asterisksCount = sign.length

    if (asterisksCount > 0) {
      const regexPattern = new RegExp(`^[^*]*\\*{${asterisksCount}}$`)
      const filteredData = items.filter(item => regexPattern.test(item.name))
      return filteredData?.length > 0
    } else {
      return false
    }
  }

  return (
    <div>
      <Box id="print_page" className="">
        <Box className=" py-2 d-flex justify-content-center">
          <Grid className="d-flex align-items-center gap-2 " container md="10" xs="12">
            <BackButton to={"/tn/report/" + barcode} />
            <span className=" mb-0 fw-bold">Generating Report:</span>
          </Grid>
        </Box>

        <Box className=" py-2 d-flex justify-content-center">
          <Grid className="d-flex align-items-center gap-2 " container md="10" xs="12">
            <div className="d-flex gap-3 align-items-center mb-1 px-2">
              <p className="fs_14px fw-bold mb-0">Item Sensitivity Rating</p>
              <p className="mb-0">
                <i className="bi bi-square-fill" style={{ color: "yellow" }}></i>
                <span className="ps-2">Moderate</span>
              </p>
              <p className="mb-0">
                <i className="bi bi-square-fill" style={{ color: "red" }}></i>
                <span className="ps-2">High</span>
              </p>
            </div>
          </Grid>
        </Box>

        <Box className=" py-2 d-flex justify-content-center ">
          <Grid className=" d-flex" container md="10" xs="12">
            <div className="row">
              {alldata?.map(({ category, items }, index) => (
                <div className="col mt-2">
                  <Box
                    className="shadow p-1 text-center text-white"
                    sx={{ backgroundColor: "#3E4755" }}>
                    <span className="fs_14px">{category}</span>
                  </Box>

                  <div className="px-2 gx-0 mt-2">
                    {" "}
                    {/* Replace "test" with "mt-2" */}
                    {items?.map((item, key) => (
                      <div key={key} className="d-flex border w-100 px-2 ">
                        <div className="border-end">
                          {item?.senstivity === "MD" && (
                            <i
                              className="bi bi-square-fill"
                              style={{ color: "yellow", marginRight: "5px" }}></i>
                          )}
                          {item?.senstivity === "HG" && (
                            <i
                              className="bi bi-square-fill"
                              style={{
                                color: isGreenCategory(category) ? "green" : "red",
                                marginRight: "5px"
                              }}></i>
                          )}
                          {!["HG", "MD"].includes(item.senstivity) && (
                            <i
                              className="bi bi-square-fill"
                              style={{ marginRight: "5px", opacity: "0%" }}></i>
                          )}
                        </div>
                        <div className=" px-2 ">
                          <span className="fs_14px">{item.name}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Render additional text based on the category */}
                  <div>
                    {category === "Fish" && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">*Includes: Oils</p>
                        )}
                        {findInGridDataByName(items, "**") && (
                          <p className="fs_10px mb-0">
                            **Includes: Clam, Crab, Lobster, Oysters, Shrimp, Mussels
                          </p>
                        )}
                      </div>
                    )}
                    {category === "Dust" && (
                      <div className="mt-2">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">
                            *Flock is used to make a variety of objects, from carpets, clothing
                            apparel, and jewel boxes to paper goods (such as picture books, posters
                            and greeting cards) that have raised or rough threads. Flocking is a
                            fiber coating proce ss that can add dimension and texture to almost any
                            article.
                          </p>
                        )}
                        {findInGridDataByName(items, "***") && (
                          <p className="fs_10px mb-0">***Flock Dust - Dust from Carpet Fibers</p>
                        )}
                        {/* <p className="fs_10px mb-0">
              **Includes: Clam, Crab, Lobster, Oysters, Shrimp
            </p> */}
                      </div>
                    )}
                    {category === "Milks/Dairy" && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">*Includes: Water & Milk</p>
                        )}
                        {findInGridDataByName(items, "**") && (
                          <p className="fs_10px mb-0">**Includes: Pasteurized Products</p>
                        )}
                      </div>
                    )}
                    {category === "Nuts & Seeds" && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">*Includes: milks/oils/waters</p>
                        )}
                      </div>
                    )}
                    {category === "Legumes" && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">*Includes: butter/oils</p>
                        )}
                      </div>
                    )}

                    {category === "Spices & Herbs" && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">*Includes: Oils, Butter, Water & Milk</p>
                        )}
                        {findInGridDataByName(items, "**") && (
                          <p className="fs_10px mb-0">**Includes: Oils & Butter</p>
                        )}
                      </div>
                    )}
                    {category === "Petro Chemicals" && (
                      <div className="mt-2   mb-2">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px ">
                            *Extracting edible Oils from Seeds & Vegetables; Also an additive in
                            gasoline, glue, varnish, ink
                          </p>
                        )}
                        {findInGridDataByName(items, "**") && (
                          <p className="fs_10px mb-0 ">
                            **Additive in foods to absorb extra water and maintain moisture. Ex. Sea
                            soning blends, dried soups, salad dressing, baking mixes, powdered drink
                            mixes
                          </p>
                        )}
                      </div>
                    )}
                    {category === "Environmental Chemicals" && (
                      <div className="mt-2   mb-2">
                        {findInGridDataByName(items, "**") && (
                          <p className="fs_10px ">**Toluene used most common in paint thinners</p>
                        )}
                      </div>
                    )}

                    {category === "Pollens" && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">
                            *Found in crude oil, paint thinners, paint brush cleaners, nail polish,
                            glues, stain removers
                          </p>
                        )}
                      </div>
                    )}
                    {category === "Misc." && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "****") && (
                          <p className="fs_10px mb-0">
                            ****Electronics Cell Phones/Portable Phones,Computers,Home
                            Theaters,Microwaves,Stereo Systems,Televisions,etc.
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Grid>
        </Box>

        <Box className="   py-2 d-flex justify-content-center">
          <EnergeticImbalance selectedText={imbalance} />
        </Box>

        {/* <Box className='py-2 d-flex justify-content-center' >
<Grid className="  py-2 w-100 shadow p-3 mb-5 bg-body rounded fw-bold" container md={8} xs={12} >
<p className="fs_14px p-2 text-center">
<span>Disclaimer- </span>  The information provided by this scan is intended for educational and nutritional purposes only and is not intended to diagnose,
treat, cure, or prevent any disease. It is not intended as conventional veterinary medical practice advice or to replace the advice or attention of
certified veterinarians. You may wish to consult your holistic veterinarian before beginning or making changes in your pets’ diet, nutritional
supplementation or exercise program. The statements on this scan have not been evaluated by the Food and Drug Administration nor are they
approved by the Board of Veterinary Medicine. If you do not have a Holistic Veterinarian, you can find one in your area by visiting
http://www.ahvma.org/
</p>

<p className="fs_14px p-2 text-center">
All materials, contents, and process within this scan, process, and material thereafter are under service mark, trademark, and copyright laws. This
scan is not to be duplicated under any authority other than Glacier Peak Holistics, a Pet Wellness, &copy; 2023 .
</p>
</Grid>
</Box> */}

        <Box className="mt-2 " sx={{ borderTop: "7px solid white", overflow: "hidden" }}>
          <ReportProducts
            className=""
            handleProducts={handleProducts}
            setProducts={setProducts}
            products={products}
          />
        </Box>
        <Box className=" d-flex justify-content-center  py-2">
          <Button
            onClick={handleSubmit}
            className="btn btn_bg_secondery"
            sx={{ borderRadius: "18px" }}>
            Submit & Email
          </Button>
        </Box>
        <NewSampelModal show={show} onHide={() => setShowModal(false)} />
      </Box>
    </div>
  )
}

export default CreateReportPreview
function NewSampelModal(props) {
  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className=" " closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="text-center w-100">
          <CheckCircleIcon style={{ fontSize: "50px", color: "#3E4755" }} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center">Report Submitted, Email Sent</h4>
        <p className="text-center">
          Report is sucessfully subbmited to admin and email sent to the client
        </p>
      </Modal.Body>
    </Modal>
  )
}

const EnergeticImbalance = ({ selectedText }) => {
  // console.log(selectedText, "selectedText")
  const data = {
    items: [
      "Bacteria",
      "Chlorine",
      "Fungus",
      "Chloride",
      "Pesticide",
      "Inflammation",
      "Mold",
      "Pain",
      "Parasites",
      "Virus",
      "Yeast",
      "Candida",
      "Herbicides",
      "Emotions",
      "Vaccinosis",
      "Thyroid"
    ]
  }

  return (
    <Grid className=" bg-white  py-2 w-100" container md={8} xs={12}>
      <Box
        className=" shadow p-1  text-center text-white w-100"
        sx={{ backgroundColor: "#3E4755" }}>
        ENERGETIC IMBALANCES
      </Box>
      <Box className="w-100 shadow p-3 mb-5 bg-body rounded">
        <p className="fw-bold fs_14px text-center">
          Highlighted areas reference imbalances that may require help to eliminate.
        </p>
        <Box
          className=" d-flex justify-content-center"
          style={{ paddingBottom: selectedText.includes("Immune System") ? 50 : 0 }}>
          <Grid
            className={` ${selectedText.includes("Immune System") ? "energetic-highlight" : ""}`}
            container
            md={6}
            xs={12}>
            <p
              className={`clickable-wrapper text-center w-100 mb-0 ${
                selectedText.includes("Immune System") ? "selected" : "text-white"
              }`}
              style={{
                backgroundColor: "#3E4755"
              }}>
              Immune System
            </p>

            {/* {selectedText&&selectedText?.map((item,index)=>(   */}
            {data.items.map((item, index) => (
              <Grid md={6} xs={12}>
                {" "}
                <div
                  // onClick={(event) => handleTextClick(item, 'red')}
                  className={` clickable-wrapper ${
                    selectedText.includes(item) ? "selected" : "text-white"
                  }`}>
                  <p
                    className={`text-center mb-0 `}
                    // style={{
                    //   backgroundColor: index % 4 >= 2 ? '#828383' : '#D1A68F',
                    //   cursor: 'pointer',
                    //   color:  'white',
                    //   fontWeight:  'normal'
                    // }}
                    style={{
                      backgroundColor: index % 4 >= 2 ? "#828383" : "#D1A68F",
                      fontWeight: selectedText.includes(item) ? "bold" : "normal"
                    }}>
                    {item}
                  </p>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Grid>
  )
}
